// Starter template
@import './page/page';
@import "./hamburger/hamburger";
@import "./module/module";

// Components
@import "./footer/footer";
@import "./header/header";
@import "./overlay/overlay";
@import "./text-block/text-block";

// Sections
@import "./sections/promo/promo";
@import "./sections/block-table/block-table";
@import "./sections/block-all/block-all";
@import "./sections/faq/faq";
@import "./sections/single-promo/single-promo";
