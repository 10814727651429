.promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 60px 32px;

    @media (max-width: $lg) {
        padding: 32px;
        flex-direction: column;
        gap: 20px; }

    img {
        border-radius: 16px;

        @media (max-width: $lg) {
            width: 100%;
            height: auto; } }

    h1 {
        font-size: 48px;
        line-height: 58px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        color: $accent-4;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            font-size: 40px;
            margin-bottom: 20px;
            line-height: 50px; } }

    p {
        margin-bottom: 24px;

        @media (max-width: $lg) {
            margin-bottom: 20px; } }

    .button {
        max-width: 256px; } }
