.block-all {
    padding: 32px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px; }

    &__block {
        >p {
            color: $accent-2;
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            margin-bottom: 24px; }

        img {
            margin-bottom: 24px;
            border-radius: 24px;

            @media (max-width: $lg) {
                width: 100%;
                height: auto; } } } }
