.footer {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-direction: column;
    background: $accent;
    position: relative;
    z-index: 2;

    > .container-main {
        height: 118px;

        @media (max-width: $lg) {
            height: auto; } }

    .container-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: $lg) {
            padding-top: 20px;
            padding-bottom: 20px;
            align-items: baseline;
            flex-direction: column-reverse;
            gap: 20px; }

        .nav {
            ul {
                display: flex;
                gap: 40px;
                row-gap: 16px;
                justify-content: flex-end;
                flex-wrap: wrap;
                max-width: 540px;

                @media (max-width: $lg) {
                    max-width: 100%;
                    justify-content: flex-start; }

                li {
                    @media (max-width: $lg) {
                        width: calc( (100% / 2) - 32px ); } }

                a {
                    font-size: 16px;
                    font-weight: 400;
                    color: $accent-2; } } } }

    &__bottom {
        background: #0B0E17;
        width: 100%;

        .container-main {
            @media (max-width: $lg) {
                padding-top: 0;
                padding-bottom: 0; } }

        ul {
            display: flex;
            align-items: center;
            height: 43px;
            justify-content: space-between;
            width: 100%;

            @media (max-width: $lg) {
                overflow-x: scroll;
                gap: 20px; }

            li {
                &:last-child {
                    @media (max-width: $lg) {
                        min-width: 100px; } } }

            a {
                color: #FFFFFF59;
                font-size: 16px;
                font-weight: 400;
                white-space: nowrap; } } } }
