body {
    background: #0B0E17;
    font-family: "Inter";
    margin: 0;
    font-weight: 500; }

.page {
    overflow-x: hidden;
    @media (min-width: 1921px) {
        max-width: none !important; }

    &__content {
        position: relative;
        overflow: hidden;
        padding-top: 183px;

        @media (max-width: $lg) {
            padding-top: 145px; }

        > .container-main {
            display: flex;
            flex-direction: column;
            gap: 24px; } } }

.container-main {
    max-width: 960px;
    margin: 0 auto;

    @media (max-width: $lg) {
        padding: 0 20px; } }

.section-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
    text-align: left;
    font-family: 'Outfit', sans-serif;
    color: $accent-4;

    @media (max-width: $lg) {
        font-size: 30px;
        line-height: 120%; } }

.text {
    color: $accent-2;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500; }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    transition-property: all;
    transition-duration: .6s;
    width: 100%;
    height: 52px;
    background: $accent-1;
    font-family: 'Outfit', sans-serif;
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
        color: #FFFFFF; } }

.link {
    display: flex;
    align-items: center;
    color: $accent-1;
    gap: 8px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    font-weight: 600; }

section {
    background: $accent;
    border-radius: 24px; }

table {
    border-collapse: collapse; }

h1,h2,h3,h4,h5,h6,p {
    padding: 0;
    margin: 0;
    line-height: 150%;
    color: #C5C5C5; }

a {
    text-decoration: none; }

button {
    border: none;
    transition-property: all;
    transition-duration: .6s;
    padding: 0;
    background: none;

    &:hover {
        opacity: 0.8; } }

@keyframes opacity {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.reviews {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $lg) {
        flex-direction: column; }

    &__item {
        background: $accent;
        border-radius: 24px;
        width: 100%;
        padding: 32px;

        @media (max-width: $lg) {
            max-width: fit-content; }

        &-header {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 24px;

            img {
                border-radius: 100%;
                object-fit: cover; } }

        &-name {
            h3 {
                margin-bottom: 8px;
                font-size: 24px;
                line-height: 29px;
                font-weight: 600;
                font-family: 'Outfit', sans-serif;
                color: $accent-4; } }

        p {
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            color: $accent-2; } } }

.team {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $lg) {
        flex-direction: column; }

    &__item {
        background: $accent;
        border-radius: 24px;
        width: 100%;

        h3 {
            padding: 32px;
            color: $accent-4;
            font-size: 24px;
            line-height: 29px;
            font-weight: 600;
            font-family: 'Outfit', sans-serif;
            border-bottom: 4px solid $accent-1;

            @media (max-width: $lg) {
                padding: 20px 16px; } }

        &-text {
            display: flex;
            align-items: center;
            padding-left: 32px;
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            font-family: 'Outfit', sans-serif;
            height: 72px;

            @media (max-width: $lg) {
                height: 60px;
                font-size: 16px;
                padding: 0 16px; }

            .dots {
                border-radius: 100%;
                background: #D9D9D9;
                width: 6px;
                height: 6px;
                margin-left: 16px; }

            img {
                margin-left: 16px;
                margin-right: 12px; } } } }

table {
    width: 100%; }

.text-block {
    .dop-block {
        padding: 32px;
        border-radius: 24px;
        h2 {
            margin-bottom: 32px; }
        p {
            margin-bottom: 32px; }

        h4 {
            margin-bottom: 32px;
            color: #F5F6F7;
            font-size: 20px;
            font-weight: 600; }

        .wp-block-table table tr:first-child td:last-child, .wp-block-table table tr td:last-child {
            text-align: left; }

        .wp-block-table table tr td {
            border-bottom: none;
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            width: 50%;

            span {
                width: 6px;
                height: 6px;
                border-radius: 100%;
                margin-right: 16px;
                display: inline-block;
                margin-bottom: 4px; } }

        .wp-block-table table tr td {
            &:last-child {
                span {
                    background: #FF4B4B; } }
            &:first-child {
                span {
                    background: #AFFF70; } } }

        .wp-block-table table tr:first-child {
            border-bottom: 4px solid #141928; }

        .wp-block-table table tr:first-child td {
            font-size: 24px;
            font-weight: 600; } }

    .betting {
        padding: 32px;
        border-radius: 24px;

        @media (max-width: $lg) {
            padding: 12px; }

        h2 {
            margin-bottom: 24px; }

        &__block {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 32px; }

        &__item {
            display: flex;
            align-items: center;
            background: #0E111C;
            border-radius: 24px;
            padding-right: 26px;
            height: 168px;

            @media (max-width: $lg) {
                flex-direction: column;
                align-items: flex-start;
                height: auto;
                padding: 0;
                gap: 12px; }

            &-btns {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-left: auto;

                @media (max-width: $lg) {
                    width: 100%;
                    flex-wrap: wrap;
                    padding: 0 20px;
                    padding-bottom: 12px; }

                .betting__item-raiting {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    @media (max-width: $lg) {
                        max-width: calc( 100% - 40px );
                        justify-content: center; }

                    span {
                        font-size: 16px;
                        font-weight: 500;
                        color: #F5F6F7; } }

                .button {
                    max-width: 170px;
                    border-radius: 12px;
                    height: 48px;
                    font-size: 16px;
                    font-weight: 600;

                    @media (max-width: $lg) {
                        width: 100%;
                        max-width: calc( 100% - 40px ); }

                    &.yel {
                        background: #FFC278;
                        box-shadow: 0px 0px 15px 0px #FFC27859;
                        color: #090B12; }

                    &.gr {
                        background: #262F4C;
                        color: #FCFDFE; } } }

            &-text {
                max-width: 245px;
                width: 100%;
                margin-left: 52px;

                @media (max-width: $lg) {
                    padding: 0 20px;
                    margin: 0;
                    margin-top: 34px;
                    margin-bottom: 32px; }

                h4 {
                    font-size: 24px !important;
                    font-weight: 600 !important;
                    color: #A5AAC0 !important;
                    margin-bottom: 16px;
                    margin-top: 0 !important; }

                p {
                    color: #F5F6F7 !important;
                    font-size: 20px !important;
                    font-weight: 600 !important;
                    font-family: 'Outfit', sans-serif; } }

            &-logo {
                background: #20273F;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 24px 0 0 24px;
                max-width: 164px;
                width: 100%;
                height: 100%;

                @media (max-width: $lg) {
                    border-radius: 24px 24px 0 0;
                    max-width: 100%;
                    height: 160px; }

                img {
                    object-fit: cover; } } } }

    .overview {
        background: $accent;
        padding: 32px;
        border-radius: 24px;

        h2 {
            margin-bottom: 24px;
            color: #EEF0FF; }

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            @media (max-width: 767px) {
                overflow-x: scroll;
                flex-wrap: nowrap;
                padding-bottom: 12px; }

            .button {
                background: #0E111C;
                color: #8B90A6;
                font-size: 16px;
                padding: 0 20px;
                max-width: fit-content; } } } }
