.block-table {
    padding: 32px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: baseline;
            gap: 8px; } }

    &__block {
        >p {
            color: $accent-2;
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            margin-bottom: 24px; }

        .table-v1, .table-v2, .table-v3, .table-v4 {
            width: 100%;
            background: #0E111C;
            border-radius: 24px;

            @media (max-width: $lg) {
                overflow-x: scroll; }

            table {
                @media (max-width: $lg) {
                    min-width: 700px; } }

            tr {
                height: 66px;

                td {
                    color: $accent-3;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 25px;
                    border-bottom: 2px solid $accent;

                    &:nth-child(3), &:nth-child(4) {
                        text-align: center; }

                    &:first-child {
                        padding-left: 32px; }

                    &:last-child {
                        padding-right: 32px;
                        text-align: right; }

                    a {
                        color: $accent-1; } }

                &:first-child {
                    height: 88px;

                    td {
                        color: #FFFFFF;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 600;
                        font-family: 'Outfit', sans-serif;

                        &:nth-child(3), &:nth-child(4) {
                            text-align: center; }

                        &:first-child {
                            padding-left: 32px; }

                        &:last-child {
                            padding-right: 32px;
                            text-align: right; } } }

                &:last-child {
                    td {
                        border-bottom: 0; } } } }

        .table-v2 {
            table {
                @media (max-width: $lg) {
                    min-width: 550px; } }
            tr {
                td {
                    max-width: 25%;
                    width: 100%;

                    &:nth-child(2), &:nth-child(3) {
                        text-align: center; } } } }

        .table-v3 {
            .row {
                picture {
                    float: inline-start;
                    margin-top: 6px;
                    margin-right: 16px; }

                img {
                    border-radius: 100%;
                    object-fit: cover;
                    float: inline-start;
                    margin-top: 6px;
                    margin-right: 16px; } }

            td {

                &:first-child {
                    max-width: 20%;
                    width: 100%; }

                &:last-child {
                    max-width: 35%;
                    width: 100%; }

                &:nth-child(3) {
                    text-align: center; }

                .table-v3__text {
                    margin-top: 13px; } } }

        .table-v4 {
            table {
                @media (max-width: $lg) {
                    min-width: 650px; } }

            td {
                &:first-child {
                    max-width: 45%;
                    width: 100%; }

                &:nth-child(2) {
                    @media (max-width: $lg) {
                        max-width: 20%;
                        width: 100%; } }

                &:last-child {
                    @media (max-width: $lg) {
                        max-width: 20%;
                        width: 100%; } } } } } }
