$accent: #141928;
$accent-1: #0085FF;
$accent-2: #71768B;
$accent-3: #A5AAC0;
$accent-4: #EEF0FF;

$font-family-base: "Inter";

$lg: "959px";
$sm: "1023px";
$md: "767px";
