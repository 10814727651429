.header {
    width: 100%;
    z-index: 11;
    transition: all 0.6s;
    position: absolute;
    top: 0;
    left: 0;

    &__dop {
        ul {
            display: flex;
            align-items: center;
            height: 43px;
            justify-content: space-between;

            @media (max-width: $lg) {
                overflow-x: scroll;
                gap: 20px; }

            a {
                color: #FFFFFF59;
                font-size: 16px;
                font-weight: 400;
                white-space: nowrap; } } }

    &__main {
        background: $accent;

        .container-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 116px;

            @media (max-width: $lg) {
                height: 80px; } } }

    &__nav {
        &.is-active {
            left: 0;
            transition: transform .3s ease; }

        @media (max-width: $lg) {
            margin-left: 0;
            min-width: 100vw;
            width: 100%;
            left: calc( -100vw - 10px );
            height: calc( 100vh - 104px );
            position: absolute;
            background: #00000052;
            backdrop-filter: blur(10px);
            top: 123px;
            padding-top: 48px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start; }

        ul {
            display: flex;
            align-items: center;
            gap: 40px;

            @media (max-width: $lg) {
                flex-direction: column;
                gap: 30px; }

            a {
                color: white;
                font-size: 16px;
                font-weight: 400; } } }

    &__hamburger {
        display: none;

        @media (max-width: $lg) {
            display: block; } } }
